<template>
	<div
		class="news-tags"
		:style="{ maxHeight: expandingBtn ? `${newsTagsMaxHeight}px` : 'none' }"
		:class="{ 'news-tags_with-tooltip': showPercentTooltip }"
	>
		<div
			class="news-tags__content"
			:class="{ 'news-tags__content_tooltip': showPercentTooltip, 'news-tags__content_has-more-btn': expandingBtn }"
			ref="newsTagsContent"
		>
			#
			<NuxtLink
				v-for="tag in tags"
				:key="tag"
				:to="`/news/t/${tag.slug}/`"
				class="news-tag"
				:class="{ 'news-tag_tooltip': tag.percent }"
				:data-stat="tag.percent ? `${tag.percent}%` : null"
				@click.stop
			>
				{{ tag.name }}
			</NuxtLink>
		</div>
		<div
			v-if="isDisplayShowMoreContent"
			class="news-tags__more-content"
			@click="onClickMoreContent()"
			:class="{ 'news-tags__more-content_rotate': opened }"
		>
			<svg width="30" height="30" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M5 7.5L10 12.5L15 7.5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
			</svg>
		</div>
	</div>
</template>

<script>
export default {
	name: 'NewsTags',
	props: {
		tags: {
			type: Object,
			required: true
		},
		showPercentTooltip: {
			type: Boolean,
			default: false
		},
		expandingBtn: {
			type: Boolean,
			default: false
		}
	},
	data: () => ({
		newsTagsContentRefHeight: 0,
		newsTagsMaxHeight: 0,
		calcMaxHeightForClosedContent: 40,
		opened: false,
	}),
	computed: {
		isDisplayShowMoreContent() {
			return this.expandingBtn && this.newsTagsContentRefHeight > this.calcMaxHeightForClosedContent;
		},
	},
	watch: {
		tags() {
			this.$nextTick(() => this.setNewsTagsContentRefHeightByOffsetHeight());
		}
	},
	mounted() {
		if (this.expandingBtn) {
			this.handleResize();
			this.newsTagsMaxHeight = this.calcMaxHeightForClosedContent;
			window.addEventListener('resize', this.handleResize);
		}
	},
	unmounted() {
		if (this.expandingBtn) {
			window.removeEventListener('resize', this.handleResize);
		}
	},
	methods: {
		handleResize() {
			this.setNewsTagsContentRefHeightByOffsetHeight();
		},
		setNewsTagsContentRefHeightByOffsetHeight() {
			this.newsTagsContentRefHeight = this.$refs.newsTagsContent.offsetHeight;
		},
		onClickMoreContent() {
			this.opened = !this.opened;
			this.newsTagsMaxHeight = this.opened ? this.newsTagsContentRefHeight + 30 : this.calcMaxHeightForClosedContent;
		},
	},
}
</script>

<style lang="scss" scoped>
.news-tags {
	display: flex;
	min-height: 20px;
	transition: all 0.5s ease-in-out;
	overflow: hidden;

	&_with-tooltip {
		flex-shrink: 0;
		min-height: 50px;
		padding-top: 30px;
	}

	&__content {
		display: flex;
		flex-wrap: wrap;
		gap: 0 12px;
		line-height: 1.5em;
		height: 100%;

		&_tooltip {
			gap: 6px 12px;
			padding-right: 20px;
		}

		&_has-more-btn {
			height: max-content;
		}
	}

	&__more-content {
		margin-inline: auto;
		width: 30px;

		&:hover {
			cursor: pointer;
		}

		svg {
            position: relative;
            top: -5px;
			transition: all 0.5s ease-in-out;
		}

		&_rotate svg {
			transform: rotateX(180deg);
		}
	}
}

.news-tag {
	color: #309BFE;
	text-decoration: none;
	transition: all 0.25s ease-in-out;
	position: relative;

	&_tooltip {
		&::after {
			content: " ";
			position: absolute;
			top: -5px;
			left: -5px;
			width: calc(100% + 10px);
			height: calc(100% + 10px);
			background-color: transparent;
		}

		&::before {
			content: attr(data-stat);
			position: absolute;
			top: -10px;
			left: 50%;
			translate: -50%;
			color: #fff;
			padding: 5px 10px;
			font-size: 12px;
			line-height: 12px;
			opacity: 0;
			transition: all 0.25s ease-in-out;
			border-radius: 1px;
			border: 0.4px solid $dark-2;
			background: $dark-6;
			pointer-events: none;
		}

		&:hover::before {
			top: -27px;
			opacity: 1;
		}
	}
}
</style>
